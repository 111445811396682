.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  /* border: 1px transparent solid; */
}

.horizontal-menu {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.menu-item-wrapper {
  /* border: 1px transparent solid; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* for webkit, when user tap on the horizontal menu disappear the blue rectangle surrounding */
.menu-item-wrapper:focus {
  /* border: 1px blue solid; */
  outline: 0;
}

.menu-wrapper--inner:focus {
  /* border: 1px blue solid; */
  outline: 0;
}

.menu-item-wrapper.focus {
  /* border: 1px red solid; */
  /* border: none; */
  outline: 0;
}

.menu-item-wrapper.active {
  /* border: 1px red solid; */
  /* border: none; */
  outline: 0;
}

.menu-item-wrapper.hover {
  /* border: 1px blue solid; */
  border: none;
}

.menu-item.active {
  /* border: 1px green solid; */
  border: none;
}

.scroll-menu-arrow {
  /* background-color: green; */
  padding: 20px 10px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  cursor: pointer;

  /* background-color: gold; */

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
  /* background-color: green; */
  padding: 20px 10px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  cursor: pointer;

  /* background-color: gold; */

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
