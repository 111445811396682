.icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.icon-invalid::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;

  text-decoration: line-through;
}

.twitter::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 100;
  color: #800000;
  content: '\f14a';
}

.twitter::after {
  content: '\00a0\00a0';
}
